import { ProjectSettings } from '@valiot/settings'
import packageJson from '../package.json'
import Logo from 'components/Logo/Logo'
const settings: ProjectSettings = {
  version: packageJson.version,
  copilotEnabled: false,
  title: 'Prolamsa - Valiot',
  api: {
    http: import.meta.env.VITE_API_URL,
    wss: import.meta.env.VITE_API_WSS,
    valiotWorker: import.meta.env.VITE_WORKER,
    ssoUrl: import.meta.env.VITE_SSO_URL,
    routerCode: import.meta.env.VITE_ROUTER_CODE,
  },
  customerLogo: {
    src: <Logo />,
  },
  analytics: {
    enabled: false,
    // siteId: import.meta.env.VITE_MATOMO_SITE_ID,
  },
  locale: {
    default: 'es',
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  },
  support: {
    enabled: true,
  },
  tenancy: {
    enabled: true,
  },
  sidebarDefault: 'expanded',
  home: 'order/sales-orders',
  routes: [],
  dropdowns: [],
  sections: [
    {
      path: 'order',
      title: 'Órdenes',
      directory: 'orders',
      routes: [
        {
          path: 'sales-orders',
          text: 'Órdenes de Venta',
          iconSource: 'fas',
          icon: 'money-check-alt',
          component: 'SalesOrders',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'forecast-orders',
          text: 'Órdenes Previsionales',
          iconSource: 'fas',
          icon: 'dolly-flatbed',
          component: 'ForecastOrders',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
      ],
      dropdowns: [],
    },
    {
      path: 'mps',
      title: 'MPS',
      directory: 'mps',
      routes: [
        {
          path: 'generate-requirements',
          text: 'Requerimientos de Producción',
          iconSource: 'fas',
          icon: 'list-alt',
          component: 'ProductionRequirement',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'fulfillment',
          text: 'Cumplimiento',
          icon: 'clipboard-check',
          component: 'Fulfillment',
        },
      ],
      dropdowns: [],
    },
    {
      path: 'inventory',
      title: 'Inventarios',
      directory: 'inventory',
      routes: [
        {
          path: 'lots',
          text: 'Lotes',
          iconSource: 'fas',
          icon: 'warehouse',
          component: 'LotsInventory',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
      ],
      dropdowns: [],
    },
    {
      path: 'maintenance',
      title: 'Mantenimiento',
      directory: 'maintenance',
      routes: [
        {
          path: 'shifts',
          text: 'Turnos',
          iconSource: 'fas',
          icon: 'user-hard-hat',
          component: 'Shifts',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'shop-floor',
          text: 'Monitor de piso',
          iconSource: 'fas',
          icon: 'tools',
          component: 'ShopFloorMonitor',
          permissions: 'all',
          // exactUrl: false,
          hidden: false,
        },
        {
          path: 'shifts-manage',
          component: 'ManageReasonsShifts',
          exactUrl: false,
          permissions: 'all',
          text: 'Administrar motivos paros',
          hidden: true,
        },
        {
          path: 'shop-floor-manage',
          component: 'ManageReasonsFloorMonitor',
          exactUrl: false,
          permissions: 'all',
          text: 'Administrar motivos paros',
          hidden: true,
        },
      ],
      dropdowns: [],
    },
    {
      path: 'setup',
      title: 'Configuración',
      directory: 'setup',
      routes: [],
      dropdowns: [
        {
          path: 'operation',
          text: 'Datos Operativos',
          directory: 'operation',
          icon: 'industry-alt',
          iconSource: 'fas',
          routes: [
            {
              path: 'processes',
              text: 'Procesos',
              component: 'Processes',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'customers',
              text: 'Clientes',
              component: 'Customers',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'warehouses',
              text: 'Almacenes',
              component: 'Warehouses',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'workstations',
              text: 'Estaciones de Trabajo',
              component: 'WorkStations',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'facilities',
              text: 'Plantas',
              component: 'Facilities',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'customer-types',
              text: 'Catálogo de Clientes',
              component: 'CustomerCatalogues',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'material-types',
              text: 'Catálogo de Materiales',
              component: 'MaterialCatalogues',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'materials',
              text: 'Materiales',
              component: 'Materials',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'supplier-types',
              text: 'Catálogo de Proveedores',
              component: 'SupplierCatalogues',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'materials-per-supplier',
              text: 'Materiales por Proveedor',
              component: 'MaterialsPerSupplier',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'suppliers',
              text: 'Proveedores',
              component: 'Suppliers',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'material-recipes',
              text: 'Recetas de Materiales',
              component: 'MaterialRecipes',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'recipes',
              text: 'Recetas',
              component: 'Recipes',
              exactUrl: false,
              hidden: true,
            },
            {
              path: 'changeovers',
              text: 'Changeovers',
              component: 'Changeovers',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          path: 'connectivity',
          text: 'Conectividad',
          directory: 'connectivity',
          icon: 'link',
          iconSource: 'fas',
          routes: [
            {
              path: 'properties',
              text: 'Propiedades',
              component: 'Properties',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'property-categories',
              text: 'Categorías',
              component: 'Categories',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'units-of-measurement',
              text: 'Unidades',
              component: 'Units',
              permissions: 'all',
              hidden: false,
            },
            {
              path: 'currencies',
              text: 'Monedas',
              component: 'Currencies',
              permissions: 'all',
              hidden: false,
            },
          ],
        },
        {
          path: 'admin',
          text: 'Administración',
          directory: 'admin',
          icon: 'user-shield',
          iconSource: 'fas',
          permissions: ['Valiot Support', 'Admin'],
          routes: [
            {
              path: 'users',
              text: 'Usuarios',
              iconSource: 'fas',
              icon: 'user',
              component: 'Users',
              permissions: 'all',
              // exactUrl: false,
              hidden: false,
            },
            {
              path: 'user-groups',
              text: 'Grupos',
              iconSource: 'fas',
              icon: 'users',
              component: 'Groups',
              permissions: 'all',
              // exactUrl: false,
              hidden: false,
            },
            {
              path: 'facility-allocation',
              text: 'Asignación a Plantas',
              iconSource: 'fas',
              icon: 'industry',
              component: 'TenancyAdmin',
              exactUrl: false,
              hidden: false,
            },
          ],
        },
      ],
    },
  ],
}

export default settings
