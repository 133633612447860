import {
  Theme,
  getCustomTheme,
  // valiotTheme
} from '@valiot/core'

export const appTheme: Theme = getCustomTheme({
  // you can set a default theme as a base for your new theme (defaults to "valiotTheme"):
  // baseTheme: valiotTheme,
  overrides: {
    // update this object to setup a custom theme for the app
    colors: {
      brand: {
        // base: '#FF0000',
        // shade: '#FF2020'
      },
    },
  },
})
